@media screen and (max-width : 991px) and (min-width : 768px){
    #home {
        .padding-container{
            padding-right: 0px !important;
        }
        iframe{
            padding-right: 0px !important;
            padding-bottom: 50px !important;
        }
        .container-btn{
            justify-content: space-between !important;
            margin-bottom: 45px !important;
            .btn-join-our-team, .btn-download{
              font-size: 14px;
            }
            .btn-download{
              margin-left : unset !important;
            }
        }
        .logo-timeStope img{
            width : 300px
        }
        .section-info-timestope{
            max-width: 700px !important;
            padding: 20px 0px !important;
            margin: 0 auto;
        }
        .section-contact-us {
            max-width: 600px!important;
        }
    }
    #team .container-download{
        max-width : 700px !important;
        .custom-row{
            padding-top: 40px !important;
            padding-bottom: 40px !important;
            .customize-col-left img{
                width : 100%;
            }
            .position {
                padding-bottom: 10px !important;
            }
            .infomation-1, .infomation-2{
                padding-top: 10px !important;
            }
        }
        .team-developers, .team-marketing, .team-designers{
            padding-top: 60px !important;
            .line__height{
                display: none;
            }
            .name{
                display: flex;
                flex-direction: column;
              }
            .customize-col-dev{
              justify-content: flex-start !important;
            }
            img{
              width : 100%;
            }
            .name ,.position {
              text-align: center;
            }
        }
    }
    #download {
        height: 100vh !important;
    }
    #investor{
        .container-investor{
            padding: 85px 10px 40px 10px !important;
        }
        .customize-col-class-b {
            display: flex;
            justify-content: center;
            margin-bottom: 20px;
            margin-top: 20px;
        }
        .custom-row-class-a {
            justify-content: center;
            align-items: center;
        }
    }
    #shop{
        .container-shop {
            padding: 80px 20px 40px 20px !important;
        }
    }
    #detail-shop .container-detail-shop.row {
        height: 90vh !important;
        max-width: 700px !important;
        margin: 0px !important;
        .rec-carousel img {
            padding: 40px 0px;
            width: 100%;
        }
    }
    #checkout .container-detail-shop{
        height: 90vh !important;
        max-width: 700px !important;
        a.btn-action{
          width : 150px;
        }
        img.remove {
            right: 0px !important;
        }
    }
    #faq .container-investor{
        max-width: 700px !important;
        .title-frequently{
            font-size: 24px !important;
            padding-top: 50px !important;
        } 
        .list-questions{
            width : 700px !important;
        }
    }
    #history .title-history {
        font-size: 24px !important;
        padding-top: 50px !important;
    }
  
    #white-paper .container-white-paper, #feeChain-paper .container-white-paper {
        max-width: 700px !important;
        height: 90vh !important;
        .text-header{
            font-size: 24px !important;
            padding-top: 20px !important;
        }
        .customize-col-left{
            padding: 10px !important;
        }
        .item.active{
            font-size: 18px !important;
            padding-bottom: 8px !important;
        }
        .item{
            font-size: 18px !important;
            padding-bottom: 8px !important;
        }
    }
    #detail-search .container-detail-search{
        margin-top: 70px !important;
        .custom-col-logo{
            display: none;
        }
    }

    #detail-search-new .container-detail-search{
        margin-top: 70px !important;
        .custom-col-logo{
            display: none;
        }
    }

}

@media screen and (max-width : 991px){
    ul.sub-menu {
        display: flex;
        flex-direction: column;
        padding-left: 18px;
        a.item-sub {
            display: inline-block;
            text-align: left;
            text-decoration: none;
            color: #06243c;
            padding: 5px 0px;
            width: 100%;
        }
    }
    
}