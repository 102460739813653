
@import "./mobile.scss";
@import "./tablet.scss";
html, body{
    color: black;
    background-color: white;
}
/* Position and sizing of burger button */
.bm-burger-button {
  display: none;
  position: fixed;
  width: 30px;
  height: 26px;
  left: 22px;
  top: 20px;
}
 
/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
  height: 15% !important;
}
 
/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: white;
}
 
/* Position and sizing of clickable cross button */
.bm-cross-button {
  width: 24px;
  height: 36px !important;
  left : 17px !important
}
span.bm-cross {
  width: 2px !important;
  height: 25px !important; 
}
 
/* Color/shape of close button cross */
.bm-cross {
  background: #06243c;
}
 
/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}
 
/* General sidebar styles */
.bm-menu {
  background: #fff;
  padding: 1em 1em 0;
  font-size: 1.15em;
}
 
/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}
 
/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding-top: 29px;
}
 
/* Individual item */
.bm-item, .menu-item {
  display: inline-block;
  text-align: left;
  text-decoration: none;
  color: #06243c;
  padding: 10px 0px;
  width: 100%;
  &.active{
    color : #0cf5fd;
    font-weight: bold;
    outline: none !important;
  }
  &:hover{
      color : #0cf5fd;
      text-decoration: none;
  }
}
a{
  &:hover{
      color : #06243c;
      text-decoration: none;
  }
  &:focus {
      outline: none !important;
      outline-offset: 0 !important;
  }
}
 
/* Styling of overlay */
.bm-overlay {
  background:white;
}
.navbar{
  padding: 0.8rem 1.2rem !important;
}

.scrollToTop {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 999;
  width: 70px;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  // opacity: 0.5;
  img{
    width: 100%;
  }
}




@media only screen and (max-width : 425px){
  .scrollToTop {
    right: 10px;
    width: 55px;
  }
}

.scrollTop:hover{
  opacity: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


// header css
.menu-header{
    background-color: rgb(14, 36, 58);
    color : white;
    .navbar-brand{
        color: white !important;
    }

  
    @media only screen and (max-width : 991px){
      .navbar{
        justify-content: center;
      }
    }
    @media only screen and (max-width : 1240px){
      .navbar{
        .nav-item {
          .nav-link, a{
            padding-right: 0.8rem !important;
            padding-left: 0.8rem !important;
          }
        }
      }
    }
    .navbar-nav{
        .nav-item {
          float: left;
          position: relative;
          cursor: pointer;
            .nav-link, a{
                color: white !important;
                font-size: 14px;
                padding-right: 1.5rem;
                padding-left: 1.5rem;
                text-decoration: none;
                padding-top: 10px;
                padding-bottom: 10px;
            }
            &.active a{
                color: rgb(111, 242, 251) !important;
                font-weight: bold;
            }
            &:hover a{
                color: rgb(111, 242, 251) !important;
            }
            &:hover .sub-menu a{
              color: white !important;
            }
            &:not(:hover)>a{
              color: white !important;
            }
            .sub-menu{
              // &:hover>a{
              //   color: rgb(111, 242, 251) !important;
              // }
              &:not(:hover)>a{
                color: white !important;
              }
            }
            
        }
        // .item-main{
        //   .sub-menu {
        //     &:hover .item-sub{
        //         color: rgb(111, 242, 251) !important;
        //         font-weight: bold;
        //     }
        //   }
        // }
        
        .underline-text {
            height: 1px;
            background: rgb(111, 242, 251);
            margin-top: 3px;
        }
          ul.sub-menu{
            position: absolute;
            top: 165%;
            left: -30px;
            border-radius: 0 0 5px 5px;
            z-index: 9999;
            padding: 5px 0px 5px;
            border: 0px solid #0a2b3a;
            background: #0e243a;
            display: flex;
            flex-direction: column;
            width: auto;
            .item-sub{
                text-align: center;
                font-size: 14px;
                padding-top: 5px;
                padding-bottom: 10px;
                white-space: nowrap;
            }
          }
          ul.feeChain {
            width: 100%;
            left: 0px;
          }
          ul.feeChain a {
            font-weight: normal !important;
          }
        
    }
}
//reponsive header
@media only screen and (max-width : 1240px) and (min-width : 992px){
    .menu-header .navbar-nav .nav-item .nav-link{
        padding-right: 12px;
        padding-left: 12px;
        font-size: 14px;
        
    }
}
@media only screen and (max-width : 991px) and (min-width : 768px){
    .menu-header .navbar-nav .nav-item .nav-link{
        padding-right: 5px;
        padding-left: 5px;
        font-size: 11px;
    }
}
@media only screen and (max-width : 991px){
  .menu-header {
      background-color: #0e243a;
      color: white;
      position: fixed;
      width: 100%;
      top: 0;
      z-index: 9999;
  }
  .customize-collapse{
    display: none !important;
  }
  .bm-burger-button {
      display: block !important;
  }
  .menu-header div:nth-of-type(1){
    display : block !important;
  }
  
}

a{
  text-decoration: none!important;
}

#home{
  .header-main{
    background-image: url('../assets/images/backgruond.png');
    background-size: 100% 100%;
    height: 75vh;
    .row.customize-row {
        max-width: 1560px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 80px;
    }
    .custom-col-left {
      display: flex;
      justify-content: flex-end;
      .padding-container {
          display: flex;
          flex-direction: column;
          padding-right: 50px;
          padding-bottom: 128px;
          
          .time-precious, .time-money{
            color: white;
            font-size: 22px;
            font-weight: 700;
            padding-bottom: 0px;
          }
          .time-money_cus{
            margin-bottom: 20px;
          }
          .text-header-left{
            color: white;
            font-size: 14px;
            font-weight: normal;
            padding-top: 5px;
          }
          .text-header-left_cus{
            color: white;
            font-size: 14px;
            font-weight: normal;
            padding-top: 5px;
          }
          .container-btn {
            display: flex;
            justify-content: flex-start;
            margin-top: 40px;
          }
          .btn-join-our-team, .btn-download {
            background: #0cf5fd;
            padding: 10px 25px;
            border-radius: 10px;
            color: #152a37;
            font-weight: 600;
            cursor: pointer;
          }
          .btn-download{
            margin-left: 100px;
          }
      }
        
    }
    .custom-col-right {
      display: flex;
      align-items: center;
      // margin-bottom: 35px;
      iframe {
        // padding-right: 50px;
        // padding-bottom: 30px;
      }
    }
    
  }
  .section-notice-timestope{
    max-width: 1360px;
    padding: 40px 0px 20px 0;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    .list-notice{
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    .section-post {
      max-width: 700px;
      padding : 0 10px;
    }
    
    .header-post {
        display: flex;
        background: #0e243a;
        height: 40px;
        align-items: center;
        position: relative;
        border-radius: 5px;
        margin-top: 10px;
        &:hover{
          cursor: pointer;
        }
    }
    
    .number {
        color: white;
        font-size: 21px;
        padding-left: 10px;
    }
    
    .title {
        color: #fff;
        font-weight: 600;
        padding-left: 15px;
    }
    
    .create-post {
        color: #fff;
        position: absolute;
        right: 13px;
        font-weight: 600;
    }
    
    .content-post {
        background: #ddd;
        padding: 10px;
        border-radius: 7px;
        margin-top: 10px;
    }
  }
  .section-info-timestope{
    max-width: 1360px;
    padding: 40px 0px 80px 0;
    margin: 0 auto;
    .cus-color-and-font {
        text-align: center;
        font-size: 22px;
        font-weight: bold;
        color: #152a37;
    }
    
    .cus-color {
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        color: #929497;
    }
    
    .logo-timeStope {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
    }
    
    .row.custom-row-info {
        max-width: 1024px;
        margin: 0 auto;
    }
    
    .custom-col-info {
        // justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
    }
    
    .title-icon {
        font-size: 20px;
        font-weight: 700;
        color: #06243c;
        padding-top: 10px;
    }
    
    .class-icon.icon-time-search {
        padding: 12px;
    }
    
    .text-icon{
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        color: #929497;
        a{
          color : red;
        }
    }
    
    .text-icon.text-icon-1 {
        padding-top: 6px;
    }
  }
  .section-contact-us {
      max-width: 800px;
      margin: 0 auto;
      padding: 30px 0px;

      .title-contact-us {
        text-align: center;
        font-size: 22px;
        font-weight: bold;
        color: #152a37;
        padding-bottom: 20px;
    }

    .text-contact {
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        color: #929497;
    }

    .formform-contact-us {
        padding-top: 20px;
    }

    .row-name {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .first-name {
        width: 50%;
        display: flex;
        input{
          color :#06243c;
          padding : 10px;
          background-color: #e6e7e8;
          height: 45px;
          width: 97%;
          border: 0px solid;
          border-radius: 5px;
          &::placeholder {
            padding-left: 12px;
            font-size: 14px;
            opacity: 0.7;
          }
          
        }
        &:nth-of-type(2){
          justify-content: flex-end;
        }
    }
      .email-address {
        width: 100%;
        display: flex;
        input{
          color :#06243c;
          padding : 10px;
          background-color: #e6e7e8;
          height: 45px;
          width: 100%;
          border: 0px solid;
          border-radius: 5px;
          &::placeholder {
            padding-left: 10px;
            font-size: 14px;
            opacity: 0.7;
          }
          
        }
    }

    .email-address {
        background: #e6e7e8;
        margin-top: 20px;
        border-radius: 5px;
        height: 45px;
    }
    .your-comment {
        padding-top: 20px;
      textarea {
          color :#06243c;
          padding : 10px;
          width: 100%;
          background: #e6e7e8;
          border-radius: 5px;
          border: 0px solid;
          resize: none;
          &::placeholder {
            padding-top: 5px;
            padding-left: 12px;
            font-size: 14px;
            font-weight: 400;
            opacity: 0.7;
          }
      }
    }
    .center-btn {
        display: flex;
        justify-content: center;
        padding-top : 30px;
        .btn-submit {
            padding: 7px 50px;
            background: #06243c;
            border-radius: 8px;
            color: white;
            font-weight: 700;
            font-size: 17px;
            cursor: pointer;
        }
    }
  }

}
#footer {
  background: #06243c;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px;
  .container-icon {
    display: flex;justify-content: center;align-items: center;
    img{margin-right: 15px;}
  }
  .terms-of-use{
    color: white;
    padding-top: 12px;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
  }
  .copy-right {
      color: white;
      padding-top: 12px;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
  }
}
// page download

div#download {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .image-blaa-chat{
    width: 100%;
  }

  .container-download.row {
    max-width: 1024px;
    margin: 0 auto;
    overflow-x: hidden;
    .custom-col-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
  }
  
  .text-invitation-code {
    color: #06243c;
    font-weight: bold;
    font-size: 20px;
    img{
      position: absolute;
      right: 0;
    }
  }
  
  .input-code {
    background: #e6e7e8;
    width: 80%;
    overflow: hidden;
    border-radius: 6px;
    margin: 12px 0;
      input{
        color : #06243c;
        background: #e6e7e8;
        border-radius: 5px;
        border: 0px solid;
        padding: 15px 10px;
        width: 100%;
        font-size: 30px;
        font-weight: bold;
        color: #2679f1;
        &:focus{
          outline : none !important;
        }
        &::placeholder {
          font-size: 30px;
          font-weight: 400;
          opacity: 0.7;
          color : #929497;
        }
      }
  }
  
  .after-install {
      font-size: 14px;
      color:  #929497;
      font-weight: 500;
  }

    .custom-col-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    span.color-text {
      color: #0cf5fd;
    }
    .text-color-and-size{
      color: #06243c;
      font-weight: bold;
      font-size: 22px;
    }
    .text-color-grey{
      font-size: 14px;
      font-weight: 500;
      color: #929497;
    }
    .container-download-app {
      display: flex;
      padding: 20px 0 40px 0;
    }
  }
}

// page team
div#team {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    .container-download {max-width: 1240px;margin: 0 auto;padding: 60px 10px;}

    .title-TimeStope {
      text-align: center;
      font-weight: 700;
      font-size: 30px;
      padding-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      span {
          padding-bottom: 10px;
      }
    }
    .btn-center {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 5px;
        margin-bottom: 75px;
        a.link-join-team{
          text-decoration: none;
          text-decoration: none;
          border-bottom: 1px solid white !important;
          border-right: 1px solid white !important;
          box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important;
          overflow: hidden;
          border-radius: 10px;
        }
    }

    .btn-join-team {
        background: #06243c;
        color: white;
        padding: 10px 50px;
        border-radius: 10px;
        font-size: 16px;
        font-weight: bold;
    }
    //@extend

    .row.custom-row {
      padding-top: 80px;
      padding-bottom: 100px;
         .customize-col-left {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .customize-col-right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            // &::-webkit-scrollbar {
            //   width: 1em;
            // }
            // &::-webkit-scrollbar-track {
            //   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            // }
            // &::-webkit-scrollbar-thumb {
            //   background-color: red;
            //   outline: 1px solid red;
            // }
        }

        .name {
            color: #0b5294;
            font-size: 24px;
            font-weight: bold;
        }

        .position {
            color: #06243c;
            font-weight: 600;
            padding-top: 7px;
            font-size: 16px;
            padding-bottom: 15px;
        }

        .infomation-1 {
            color: #929497;
            font-size: 14px;
            font-weight: 400;
            padding-top: 15px;
        }

        .infomation-2 {
            color: #929497;
            font-size: 14px;
            font-weight: 400;
            padding-top: 15px;
        }
    }

    .team-developers, .team-marketing, .team-designers{
        padding-top: 120px;
        .title {
            font-size: 24px;
            font-weight: 700;
            color: #06243c;
            padding-bottom: 50px;
        }
        .customize-col-dev {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: 20px;
          padding-bottom: 30px;
        }
        
        .name {
            color: #06243c;
            font-size: 18px;
            font-weight: bold;
            padding-top: 20px;
        }
        
        .position {
            color: #929497;
            font-weight: 400;
            padding-top: 7px;
            font-size: 14px;
            padding-bottom: 15px;
        }
    }  
}

//div#investor {display: flex;justify-content: center;}

div#investor {
  display: flex;justify-content: center;
  .container-investor {
    max-width: 1240px;
    margin: 0 auto;
    padding: 50px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow-x: hidden;
  }

  .title {
      text-align: center;
      font-size: 16px;
      padding-bottom: 2px;
  }

  .line-width {
      width: 150px;
      height: 1px;
      background: #06243c;
  }

  span.color-font-weight {
      font-size: 27px;
      font-weight: bold;
  }

  .avatar-CEO {
      width: 145px;
      padding: 50px 0 20px 0;
  }

  .name-CEO {
      font-size: 20px;
      font-weight: bold;
  }

  .line-height {
      height: 50px;
      width: 15px;
      background: #06243c;
      margin: 20px 0;
  }

  .btn-invest {
      background: #06243c;
      padding: 10px 55px;
      border-radius: 10px;
      font-weight: bold;
      color: white;
      margin: 20px 0 40px 0;
  }

  .row.custom-row-class-b {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 40px 0 40px 0;
  }
  .list-class-a {
      padding: 20px 0;
  }
  .customize-col-class-a {margin: 25px;}
}
//@extend
div#faq {
  display: flex;
  justify-content: center;
  .container-investor {
    max-width: 1240px;
    margin: 0 auto;
    padding: 50px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    }

    .title-welcome {display: flex;justify-content: center;align-items: center;flex-direction: column;
      span {
          color: #929497;
          font-size: 14px;
          padding-bottom: 10px;
      }
    }

    .title-frequently {
        font-size: 30px;
        font-weight: 700;
        padding-top: 20px;
        padding-bottom: 10px;
        color: #06243c;
        
    }

    

    .list-questions {
      padding: 5px 0px 40px 0;
      width: 1024px;
    }

    .item-question {
        padding: 20px 0;
    }

    .title-question {
        color: #06243c;
        font-size: 16px;
        font-weight: 600;
    }

    .description-question {
        color: #929497;
        font-size: 14px;
        padding-top: 5px;
        span.read-more {
          color: #0b5294;
          font-size: 12px;
          font-weight: 500;
        }
    }

    .answer {
        color: #929497;
        font-size: 14px;
        padding-top: 5px;
    }
}
// History

div#history {
  background-image: url('../assets/images/53.png');
  background-size: contain;
  .container-history {
    max-width: 1024px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px 0;
    overflow: hidden;
    img.image-desktop{
      width: 100%;
    }
    img.image-mobile{
     display: none;
    }
  }

  .title-history {
      font-size: 30px;
      font-weight: 700;
      color: #06243c;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      flex-direction: column;
      padding-bottom: 60px;
      padding-top: 30px;
      span {
          padding-bottom: 14px;
          background-color: white;
      }
  }
}

#white-paper, #feeChain-paper{
  display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    .container-white-paper {
      margin: 0 auto;
      max-width: 1024px;
      height: 80vh;
  }
  

  .row-team-member {
      padding-bottom: 40px;
      .name {
        color: #2679f1;
        font-size: 24px;
        font-weight: bold;
      }
      .position {
          padding-bottom: 15px;
          color: #06243c;
          font-weight: 600;
      }
    
      .infomation-1 {
          color: #929497;
          font-size: 14px;
      }
  }
  .row-team-member-employ{
    padding-bottom: 40px;
    .name {
      color:#06243c;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
    }
    .position {
      color: #929497;
      font-size: 14px;
      text-align: center;
    }
    .customize-col-dev{
      padding-bottom: 20px;
    }
  }
  .title-team{
    font-size: 24px;
    font-weight: 700;
    color: #06243c;
    padding-bottom: 15px;
  }

  
  .title-white-paper {
    font-size: 30px;
    font-weight: 700;
    color: #06243c;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    padding-top: 40px;
    span.text-header {
        padding-bottom: 14px;
        background-color: white;
    }
  }
  .customize-col-left {
    background: #e6e7e8;
    border-radius: 10px;
    padding: 20px 20px 10px 20px;
    height: 500px;
    width: 100%;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(133, 121, 121, 0.3);
    }
    &::-webkit-scrollbar-thumb {
      background-color: #929497;
      outline: 1px solid #929497;
    }
  }

  .customize-col-right {
      height: 500px;
      width: 100%;
      overflow-y: scroll;
      padding: 10px 20px;
       &::-webkit-scrollbar {
          width: 2px;
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(133, 121, 121, 0.3);
        }
        &::-webkit-scrollbar-thumb {
          background-color: #06243c;
          outline: 1px solid #06243c;
        }
  }
  .row.custom-row-table {
    border: 2px solid #e6e7e8;
    border-radius: 5px;
  }

  .custom-col2-23 {
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 2px solid #e6e7e8;
      color: #06243c;
      font-size: 16px;
      font-weight: 700;
  }

  .download-blaa-white-paper {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 30px;
  }

  span.text-download {
      font-size: 22px;
      font-weight: 700;
      color: #06243c;
  }

  img.icon-download {
      width: 30px;
      margin-left: 10px;
      object-fit: cover;
  }

  .item {
      color: #06243c;
      font-weight: 300;
      font-size: 16px;
      padding-bottom: 17px;
      cursor: pointer;
      span.color-font-weight {
          font-weight: 600;
      }
      &:hover{
        font-weight: bold;
      }
  }

  .item.active {
      font-size: 20px;
      font-weight: bold;
      cursor: pointer;
      span.color-font-weight {
          font-weight: 700;
      }
  }
  .description{
    .title-section {
        color: #06243c;
        font-size: 16px;
        font-weight: bold;
        padding-bottom: 15px;
    }
    
    .text-section, ul li {
        color: #929497;
        font-size: 14px;
        padding-bottom: 10px;
    }
    span.number-text {
      border-radius: 50%;
      border: 1px solid;
      padding: 0px 4px;
      text-align: center;
      margin-left: 10px;
      font-size: 12px;
  }

    .image-section-1 {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
    .text-table {
        text-align: center;
        font-size: 13px;
        padding: 10px;
        color: #06243c;
    }
  }
}

#feeChain-paper .title-white-paper {
  padding-top: 20px;
}
#feeChain-paper {
  .customize-col-left {
    height: 600px;
  }
  .customize-col-right {
    height: 600px;
    padding: 0 10px;
  }
  .description img{
    width: 100%;
  }
}

//@extend

div#shop {
  display: flex;
  justify-content: center;
  align-items: center;
  .container-shop {
    max-width: 1024px;
    margin: 0 auto;
    // height: 80vh;
    padding: 30px 0;
}
.container-cart {background-color: #06243c;position: fixed;right: 0;top: 14%;display: flex;justify-content: center;width: 45px;height: 100px; align-items: center;}

.custom-cart {
  display: flex;
  position: relative;
}

.amount-order {
  width: 18px;
  height: 17px;
  border: 1px solid #0cf5fd;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  right: -4px;
  top: -2px;
  overflow: hidden;
  z-index: 999;
  background: #06243c;
  color: white;
  font-size: 10px;
  font-weight: bold;
}
.container-image {
    width: 100%;
    background: #e6e7e8;
    padding: 30px 35px;
    justify-content: center;
    align-items: center;
    display: flex;
    overflow: hidden;
    img{
      width: 80%;
    }
  }

  img.logo-timestope {
      padding-top: 5px;
  }

  .text-shirt {
      padding: 5px 0;
      font-size: 16px;
      font-weight: 500;
  }

  .money {
      color: #2679f1;
      font-size: 16px;
  }

  .container-combo {
    width: 100%;
    background: #e6e7e8;
    padding: 40px 35px;
    justify-content: center;
    align-items: center;
    display: flex;
    overflow: hidden;
    img{
      width: 80%;
    }
  }
  .customize-col {
    padding: 50px 50px 20px 50px;
  }

  .combo-image {
    display: flex;justify-content: center;align-items: center;flex-direction: row;
    img{
      width: 25%;
      margin-right: 15px;
    }
  }


}

#detail-shop{
  display: flex;
  justify-content: center;
  padding: 10px;
  .container-cart {background-color: #06243c;position: fixed;right: 0;top: 14%;display: flex;justify-content: center;width: 45px;height: 100px; align-items: center;}
  .rec-arrow-right, .rec-arrow-left{
    // display: none;
  }
  .chowzb, .cdjYql {
    font-size: 1.3em !important;
    background-color: #06243c !important;
    color: white;
    box-shadow: 0 0 2px 0px #06243c !important;
    height: 35px !important;
    width: 35px !important;
    min-width: 35px !important; 
    line-height: 33px !important;
    // margin-right: 5px;
  }
  .hCVuYx, .cdiyVv {
    font-size: 1.3em !important;
    background-color: #06243c !important;
    color: white;
    box-shadow: 0 0 2px 0px #06243c !important;
    height: 35px !important;
    min-width: 35px !important; 
    line-height: 33px !important;
    width: 35px !important;
    // margin-left: 5px;
  }
  

  .custom-cart {
    display: flex;
    position: relative;
  }
  .amount-order {
    width: 18px;
    height: 17px;
    border: 1px solid #0cf5fd;
    border-radius: 50%;
    text-align: center;
    position: absolute;
    right: -4px;
    top: -2px;
    overflow: hidden;
    z-index: 999;
    background: #06243c;
    color: white;
    font-size: 10px;
    font-weight: bold;
  }
  .container-detail-shop.row {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1024px;
    width: 100%;
  }

  .name-of-product {
      font-size: 22px;
      font-weight: bold;
      padding-bottom: 12px;
      color: #06243c;
  }

  .price-of-product {
      font-weight: 500;
      color: #2679f1;
      padding-bottom: 25px;
  }

  .text-size {
      font-size: 22px;
      font-weight: bold;
      padding-bottom: 12px;
      color: #06243c;
  }

  .container-size {
      display: flex;
      padding-bottom: 45px;
  }

  .btn-size{
      border: 1px solid #06243c;
      padding: 5px;
      font-size: 13px;
      font-weight: 600;
      background: white;
      color: #06243c;
      margin-right: 10px;
      border-radius: 7px;
      width: 48px;
      cursor: pointer;
      text-align: center;
      &.active{
        padding: 5px 10px;
        font-size: 13px;
        font-weight: 600;
        background: #06243c;
        color: white;
        margin-right: 10px;
        border-radius: 7px;
        cursor: pointer;
        width: 48px;
        text-align: center;
      }
  }

  .text-amount {
      font-size: 22px;
      font-weight: bold;
      padding-bottom: 12px;
      color: #06243c;
  }

  .container-rise-amount {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      margin-top: 21px;
  }

  .btn-amount{
      color: #06243c;
      font-weight: 700;
      font-size: 20px;
      padding: 1px 30px;
      border: 1px solid #06243c;
      border-radius: 8px;
      cursor: pointer;
  }

  .show-amount {
      color: #06243c;
      font-weight: 700;
      font-size: 20px;
      padding: 1px 80px;
      border: 1px solid #06243c;
      border-radius: 8px;
      margin-left: 10px;
      margin-right: 10px;
      background: #e6e7e8;
      width: 175px;
  }

  .text-exchange-size {color:#2679f1;font-size: 14px;font-weight: 400;text-align: right;padding-bottom: 3px;}

  div#detail-shop {
      display: flex;
      justify-content: center;
  }

  .table-exchange-size {
      width: 345px;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      align-items: flex-end;
  }

  .line-width {
      width: 75px;
      height: 1px;
      background: #2679f1;
  }

  .btn-add-to-cart {
      background: #06243c;
      color: white;
      padding: 7px 20px;
      border-radius: 5px;
      margin-right: 10px;
      width: 160px;
      text-align: center;
      font-weight: 700;
  }
  
  .btn-add-to-buy {
      background: #06243c;
      color: white;
      padding: 7px 20px;
      border-radius: 5px;
      margin-right: 10px;
      width: 160px;
      text-align: center;
      font-weight: 700;
  }
  // rec
  .rec-dot {
    box-shadow: none;
    background-color: #e6e7e8;
    width: 15px;
    height: 15px;
  }
  .rec-dot_active {
    box-shadow: none;
    background-color: #06243c;
    width: 20px;
    height: 20px;
  }
  
  .rec-pagination {
    display: flex;
    align-items: center;
  }
  .rec-carousel {
      background: #e6e7e8;
      cursor: pointer;
      img{
        padding: 40px 0px;
        cursor: pointer;
      }
  }
  div[tabindex]{
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  .rec, .rec.rec-swipable-1, .kyQlHw:focus, img{
    outline: none !important;
    div{
      outline: none !important;
    }
    
  }
}

#checkout{
  display: flex;
  justify-content: center;
  padding : 10px;
  .title-billing {
      font-size: 24px;
      font-weight: bold;
      text-align: center;
      padding-bottom: 30px;
  }

  .row-name {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      .first-name, .last-name{
        width : 50%;
      }
      .last-name{
        display: flex;
        justify-content: flex-end;
      }
  }
  .width_97{
    color: #06243c;
    padding: 10px;
    background-color: #e6e7e8;
    height: 40px;
    width: 97%;
    border: 0px solid;
    border-radius: 5px;
  }
  .width_100{
    color: #06243c;
    padding: 10px;
    background-color: #e6e7e8;
    height: 40px;
    width: 100%;
    border: 0px solid;
    border-radius: 5px;
    margin-top: 20px;
  }

  .container-cart {
    background-color: #06243c;
    position: fixed;
    right: 0;
    top: 14%;
    display: flex;
    justify-content: center;
    width: 45px;
    height: 100px; 
    align-items: center;
  }
  
  .rec-arrow-right, .rec-arrow-left{
    display: none;
  }

  .custom-cart {
    display: flex;
    position: relative;
  }

  .amount-order {
    width: 18px;
    height: 17px;
    border: 1px solid #0cf5fd;
    border-radius: 50%;
    text-align: center;
    position: absolute;
    right: -4px;
    top: -2px;
    overflow: hidden;
    z-index: 999;
    background: #06243c;
    color: white;
    font-size: 10px;
    font-weight: bold;
  }
  .container-detail-shop.row {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1024px;
  }

  .title-order {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    padding-bottom: 30px;
}

.body-list-product {
    background: #e6e7e8;
    color: #06243c;
    font-size: 18px;
    padding: 5px 5px 10px 10px;
    max-height: 250px;
    min-height: 240px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(133, 121, 121, 0.3);
    }
    &::-webkit-scrollbar-thumb {
      background-color: #06243c;
      outline: 1px solid #06243c;
    }
    .item-center{
      margin-left: 10px;
      position: relative;
      img.remove {
        position: absolute;
        cursor: pointer;
        right: 10px;
      }
    }
    .item-product {
      display: grid;
      grid-template-columns: 34% 33% 33%;
      padding-bottom : 10px;
    }
  }
  .btn-action {
    text-decoration: none;
    border-bottom: 1px solid white !important;
    border-right: 1px solid white !important;
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
    background: #06243c;
    color: white;
    padding: 9px;
    font-size: 16px;
    font-weight: bold;
    width: 220px;
    text-align: center;
    border-radius: 10px;
  }

  .center-btn {
    padding-top: 15px;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-list-product {
      display: grid;
      grid-template-columns: auto auto auto;
      background: #06243c;
      color: white;
      font-size: 18px;
      padding: 10px;
  }

  .item-center {
    text-align: center;
  }

  .footer-list-product {
      display: grid;
      grid-template-columns: auto auto auto;
      background: #06243c;
      color: white;
      font-size: 18px;
      padding: 10px;
  }

  .container-total {
      display: grid;
      grid-template-columns: auto auto auto;
      background: #06243c;
      color: white;
      font-size: 18px;
      padding: 10px;
      margin-top: 16px;
      .total-money{
        padding-left : 20px;
      }
  }
}

div#deleteAccount {
  display: flex;
  justify-content: center;
  .container-investor {
    max-width: 1240px;
    margin: 0 auto;
    padding: 50px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    }
    .mainTitle {
      font-size: 30px;
      font-weight: 700;
      padding-top: 20px;
      padding-bottom: 10px;
      color: #06243c;
    }
    .subTitle {display: flex;justify-content: center;align-items: center;flex-direction: column;
      span {
          color: #929497;
          font-size: 14px;
          padding-bottom: 10px;
      }
    }
    .requestContent {
      padding: 5px 0px 40px 0;
      width: 1024px;
    }
    .importantText {
      color: red;
    }
    .requestForm {
      padding: 5px 0px 40px 0;
      width: 50%;
      display: flex;
      flex-direction: column;
    }
    p {
      margin-top: 1rem;
      margin-bottom: 0;
    }
    .halfInput {
      width: 50%;
    }
    .reasonInput {
      height: 100px;
    }
    .submitButton {
      font-size: 15px;
      font-weight: bold;
      margin-top: 20px;
      padding: 10px 0;
      color: #fff;
      background-color: #525050;
      border: 2px solid #525050;
      border-radius: 8px;
    }
}
@media only screen and (min-width : 991px){
  .time-search-new-app .menu-header{
    display: none;
  }
  .detail-search-new .menu-header{
    display: none;
  }
}

#time-search, #time-search-new{
  .container-time-search {display: flex;justify-content: center;align-items: center;flex-direction: column;height: 35vh;}

  .logo-timeStope__search {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 40px;
  }

  .container_input-search {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 0px;
      background-color: #e6e7e8;
      border-radius: 8px;
      img{
        margin: 0 10px;
        width : 25px;
        cursor: pointer;
      }
  }

  input[type="text"] {
      background: #e6e7e8;
      border: 0px;
      width: 450px;
      color : #06243c;
      font-size : 16px;
      font-weight: 500;
  }
  input[type="text"]:focus {
      outline : none;
  }

  .line___height-search {
      width: 1px;
      height: 30px;
      background: #06243c;
      margin-right: 5px;
  }
  
}

#detail-search, #detail-search-new{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width : 100%;
  .custom-col-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    img.image-logo-search {
      width: 156px;
      margin-right: 20px;
    }
  }
  .container-detail-search {
    width: 80%;
    margin-top : 30px;
    .custom-row{
      width : 100%;
    }
    .custom-col-input{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6px 10px;
      background-color: #e6e7e8;
      border-radius: 8px;
      img{
        width : 25px;
      }
      img.image-cus {
        cursor: pointer;
      }
      input[type="text"] {
        background: #e6e7e8;
        border: 0px;
        width: 93%;
        color: #06243c;
        padding: 0 20px;
        font-size: 16px;
        font-weight: 500;
      }

      input[type="text"]:focus {
          outline : none;
      }

      .line___height-search {
          width: 1px;
          height: 30px;
          background: #06243c;
          margin-right: 5px;
      }

    }
    
  }
  .result-stats {
    color: #70757a;
    padding: 10px 0 20px 0;
    span.text-font-bold {
      font-weight: bold;
      color: #06243c;
    }
  } 
  .col-banner{
    margin-top : 20px;
    padding : 0px;
    img{
      width : 100%
    }
  }
  .list-data {
    padding: 0px;

    .item {
      margin-bottom: 20px;
      .displayLink a{  color: #202124 }

      .htmlTitle a{color : #1a0dab;padding: 5px 0;font-size: 20px;line-height: 1.3;}

      .htmlTitle a:hover{text-decoration: underline !important;}

      .htmlTitle a:visited {
        color: #609;
      }

      .htmlSnippet {
        font-size: 14px;
        color: #4d5156;
      }
    }
    
  }

}

.logo__page-search{
  display: none;
}


@media only screen and (max-width : 991px){
  .page-time-search{
    .menu-header{
      background : white !important;
      .bm-burger-bars{
        background : #223141 !important;
      }
      .bm-burger-button button:focus {
          outline: none !important
      }
      .logo-timestope{
        display: none;
      }
      .logo__page-search{
        display: block;
      }
    }
    #time-search .logo-timeStope__search{
      display: none;
    }
    #time-search-new .logo-timeStope__search{
      display: none;
    }
  }

}

@media only screen and (max-width : 1023px){
  .download img.invitation-code{
    display: none;
  }
}

.menu-header div:nth-of-type(1){
  display : none;
}
.menu-header .underline-text{
  display: block !important;
}
.nav_custom-pagination{
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  .button-load-more{
    display: none;
  }
  .custom_page-item {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #929497;
    background-color: #fff;
    cursor: pointer;
    
    &.disabled{
      opacity: 0.5;
      cursor: no-drop !important;
    }
  }

  .custom_page-item_number {
      position: relative;
      display: block;
      padding: 0.5rem 0.75rem;
      margin-left: -1px;
      line-height: 1.25;
      color: #929497;
      background-color: #fff;
      cursor: pointer;
      &.active{
        font-weight: bold;
        color: #06243c;
      }
  }
}


// page outside
div#outside {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  .container-outside {max-width: 1240px;margin: 0 auto; padding: 60px 10px; height: auto;}
  .container-team-outside{
    // background: #e6e7e8;
    padding: 10px;
    border-radius: 10px;
  }
  .benefits-team{
    margin-top: 40px;
    border-left: 5px solid #2679f1;
    padding-left: 10px;
    max-width: 820px;
    margin: 0 auto;
    // @media screen and (max-width : 991px) and (min-width : 768px){
    //   max-width: 650px;
    // }

    // @media screen and (max-width : 767px) and (min-width : 425px){
    //   max-width: 400px;
    // }
  }
  .title-TimeStope {
    text-align: center;
    font-weight: 700;
    font-size: 30px;
    padding-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    span {
        padding-bottom: 10px;
    }
  }
  .btn-center {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 5px;
      margin-bottom: 45px;
      a.link-join-team{
        text-decoration: none;
        text-decoration: none;
        border-bottom: 1px solid white !important;
        border-right: 1px solid white !important;
        box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important;
        overflow: hidden;
        border-radius: 10px;
      }
  }

  .btn-join-team {
      background: #06243c;
      color: white;
      padding: 10px 50px;
      border-radius: 10px;
      font-size: 16px;
      font-weight: bold;
  }
  .customize-col {
    display: flex;
    justify-content: center;
    padding: 20px;
    // flex-direction: column;
  }
}
div#reset {
  height: 85vh;
  background: #e6e7e8;
  justify-content: center;
  align-items: center;
  display: flex;
  @media screen and(max-width : 425px) {
    height: auto;
    padding-top : 100px;
    padding-bottom : 60px;
  }
}
.container-reset {
  margin: 0 auto;
  max-width: 425px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  @media screen and(max-width : 425px) {
    max-width: 90%;
    padding-left: 10px;
    padding-right: 10px;
  }
  .userId {
    display: flex;
    padding-bottom: 30px;
  }
  
  .text-id {
    color: #06243c;
    padding-right: 10px;
    white-space: nowrap;
  }
  
  .userId input[type="text"] {
    border: 0px solid;
    border-bottom: 1px solid #06243c;
    width: 300px;
  }
  
  .witness {
    display: flex;
    padding-top: 35px;
    padding-bottom: 35px;
  }
  
  input[type="text"] {
    border: 0px solid;
    border-bottom: 1px solid #06243c;
    width: 260px;
    @media screen and(max-width : 425px) {
      width: 200px;
    }
  }
  
  .css-2b097c-container {
    width: 334px;
    &:focus{
      outline: none !important;
    }
    @media screen and(max-width : 425px) {
      width: 270px;
    }
  }
  
  .Name {
    display: flex;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  
  .text-name-eng {
    color: #06243c;
    padding-right: 10px;
    white-space: nowrap;
  }
  
  .male {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  input[type="radio"] {
    margin-right: 10px;
  }
  
  .Name-country {
    display: flex;
    padding-bottom: 30px;
  }
  input:focus {
    outline: none !important;
  }
  
  .text-name-country, .text-mail {
    color: #06243c;
    padding-right: 10px;
    white-space: nowrap;
  }
  
  .Name-eng {
    display: flex;
    padding-bottom: 30px;
  }
  
  .mail {
    display: flex;
    padding-bottom : 20px;
  }
  
  .birth-day {
    display: flex;
    align-items: center;
    padding-bottom: 30px;
  }
  .react-date-picker__wrapper{
    border: 0px solid !important;
    border-bottom: 1px solid !important;
  }
  
  .text-birth-day {
    color: #06243c;
    padding-right: 10px;
    white-space: nowrap;
  }
  .container-btn-submit {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
  }

  .btn-submit {
      background: #06243c;
      color: white;
      padding: 5px 20px;
      border-radius: 6px;
      &:hover{
        cursor: pointer;
      }
  }
}

.container-privacy {
  width: 100%;
            background-color: #FAFAFA;
            padding: 64px 16px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
  .content-privacy {
    width: 100%;
            max-width: 968px;
            border: 1px solid black;
            padding: 32px;
            margin: 0 auto;
            font-family: sans-serif;
            background-color: white;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            box-shadow: 0 16px 16px 8px lightgray;
  }
  .content-privacy h1,
  .content-privacy h2,
  .content-privacy h3,
  .content-privacy h4,
  .content-privacy h5,
  .content-privacy h6 {
      border-bottom: 1px solid darkgray;
  }
  .content-privacy table {
    border: 1px solid black;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
}
.content-privacy table th,
.content-privacy table td {
    border: 1px solid black;
}
}

.privacy-notice {
  .container-box{
    padding: 5rem 0 4rem;
  }
  .container-title{
    font-size: 100px; 
    text-transform: uppercase; 
    color: #1b365d; 
    font-weight: bold; 
    line-height: 1; 
    margin: 0;
  }
  .container-title-sub {
    font-weight: normal;
  }
  .container-contents {
    margin: 0 15%;
  }
  .thead-title {
    background: #0097dd;
    color: white;
  }
  .tbody-border {
    border: 1px solid #dbdcdd;
  }
  .tbody-setBackground {
    background-color: #00003205;
  }
}
.table_call {
  display: table-cell;
}
.font-bold {
  font-weight: bold;
}
.hide-overflow {
  overflow-x: hidden;
}
.show-overflow {
  overflow-x: scroll;
}

@media screen and (max-width: 768px) {
  .privacy-notice {
    .container-title{
      font-size: 35px;
    }
    .container-contents {
      margin: 0 5%;
    }
}
}