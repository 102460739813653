// reponsive 
@media screen and (max-width : 767px){
  div#outside .container-outside{
    width: 100% !important;
  }
    div#outside .container-outside{
      height: auto !important;
  }
    
    #white-paper, #feeChain-paper{
      .container-white-paper {
        height: auto !important;
      }
      
      .Content-white-paper {
          padding: 0 25px 25px 25px;
          .custom-row{
            margin : 0px !important;
          }
      }
      
      span.text-header {
          font-size: 24px !important;
      }
      
      .customize-col-right {
          margin-top: 30px;
          padding: 5px !important;
      }
    }
    div#faq{
      .title-welcome img{
        width : 100%;
      }
      .title-frequently {
        font-size: 24px !important;
      }
    
      .container-investor {
          padding: 65px 10px!important;
      }
      
    
      .list-questions {
          width: auto !important;
      }
    }
    
    #checkout .container-detail-shop{
      height: 100% !important;
      margin: 0 20px !important;
      padding: 65px 0px 30px 0;
      .title-order{
        padding-top : 30px
      }
      .title-billing{
        padding-top : 30px
      }
      .btn-action{
        width : 150px;
      }
      
      .center-btn {
        padding-top: 30px !important;
        height: 70px !important;
      }
      .item.item1 {
        font-size: 13px;
      }
      
      img.remove {
          right: 0px !important;
      }
    }
    #detail-shop .container-detail-shop{
      padding: 80px 0px 40px 0px !important;
      height: 100% !important;
      margin : 0px !important;
      .container-size{
        justify-content: space-between;
        .btn-size{
          margin-right: unset !important;
        }
      }
      .btn-amount{
        padding : 1px 15px !important;
      }
      .rec-pagination{
        display: none;
      }
      button:disabled {
        opacity: 0 !important;
      }
     .rec-arrow-right, .rec-arrow-left {
       display: block !important;
     }
     .cus-col-left{
       position: relative;
     }
     .table-exchange-size{
       width : auto !important;
     }
     .container-rise-amount {
        justify-content: space-between;
      }
      .show-amount{
        width : 150px !important;
      }
      .btn-add-to-cart, .btn-add-to-buy{
        margin-right: unset !important;
        width: 130px !important;
      }
     .doLzca, .fFvvjo{
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      font-size: 1.6em;
      background-color: #e6e7e8 !important;
      color: #333;
      box-shadow: none!important;
      border-radius: 50%;
      border: none !important; 
      -webkit-align-self: center;
      -ms-flex-item-align: center;
      align-self: center;
      cursor: pointer;
      outline: none;
     }
  
      .name-of-product{
        padding-top: 30px;
      }
      .rec-carousel img {
          padding: 40px 0;
          cursor: pointer;
          width: 100%;
      }
    }
    .container-cart {
      background-color: unset !important;
      position: fixed;
      right: 18px !important;
      top: -5px!important;
      display: flex;
      justify-content: center;
      width: 45px;
      height: 70px!important;
      align-items: center;
      z-index: 10000;
    }
    #home {
      .padding-container{ padding-right : unset !important}
      .time-precious, .time-money, .text-header-left , .text-header-left_cus{
        text-align: center;
      }
      .text-header-left{
        padding-bottom: 15px !important;
      }
      .container-btn{
        justify-content: space-between !important;
        margin-top: 10px!important;
        margin-bottom: 30px !important;
        .btn-join-our-team, .btn-download{
          font-size: 14px;
        }
        .btn-download{
          margin-left : unset !important;
        }
      }
      .custom-col-left {
        justify-content: center !important;
      }
      .custom-col-right{
        align-items: center;
        justify-content: center;
        iframe{
          padding: 10px !important;
        }
      }
      .section-info-timestope{
        padding : 80px 0 10px 0 !important;
      }
      .header-main {
          background-image: url('../assets/Image_Mobile/1.png') !important;
          background-size: 100% 100%!important;
          height: auto !important;
          .btn-join-our-team, .btn-download{
            padding : 9px 15px !important;
          }
      }
      .logo-timeStope img{
        width : 300px;
      }
      .section-info-timestope .custom-col-info{
        padding : 25px 0px;
        &:nth-of-type(1), &:nth-of-type(3){
          background-color: #ddd;
        }
      }
      .section-contact-us{
        padding: 30px 15px 50px 15px!important;
      }
    }
    div#download{
      height: auto !important;
      img{
        width : 100%;
      }
      .container-download{
        padding: 60px 0;
      }
      .custom-col-right{
        align-items: left !important;
      }
    }
    div#team {
      .custom-row{
        padding: 20px 30px !important;
      }
      .customize-col-left{
        img {
          width: 200px;
        }
      }
      .title-TimeStope{
        img{
          width : 100%
        }
      }
      .line__height{
        display: none;
      }
      .name{
        display: flex;
        flex-direction: column;
      }
      .customize-col-right{
        padding-top: 20px;
        align-items: center;
        
        .infomation-1, .infomation-2, .name, .position{
          text-align: center;
          padding-left: 15px;
          padding-right: 15px;
        }
       
      }
      .team-developers, .team-marketing, .team-designers{
        padding-top: 60px !important;
        padding-left: 25px;
        padding-right: 25px;
        .customize-col-dev{
          justify-content: flex-start !important;
        }
        img{
          width : 100%;
        }
        .name ,.position {
          text-align: center;
        }
      }
      
    }
    div#investor .container-investor{
      padding: 80px 10px 40px 10px !important;
      height: 100% !important;
      .customize-col-class-a {
        margin: 0px !important;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0px;
      }
      .customize-col-class-b {
        justify-content: center;
        align-items: center;
        display: flex;
        padding-top: 10px;
        padding-bottom: 20px;
      }
    }
    div#shop {
      .container-shop {
        max-width: 1024px;
        margin: 0 auto;
        padding: 80px 20px 40px 20px !important;
      }
      .text-shirt {
        text-align: center;
      }
      
      .container-image img {
        width: 100% !important;
      }
      .content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: 20px;
      }
      .container-combo{
        flex-direction: column !important;
      }
      
      .combo-image{
        img{
          width : 90% !important;
          margin-bottom: 20px;
          margin-right: unset !important;
        }
        // img.image-cus1{
        //   width : 40% !important;
        // }
      }
    }
    div#history {
      .container-history {
        .title-history{
          padding : 50px 20px 0 20px !important;
          font-size : 20px !important;
          text-align: center;
        }
        img.image-desktop{
          display: none
        }
        img.image-mobile{
          display: block !important;
          width : 100%;
        }
      }
    }
    #time-search, #time-search-new{
      padding: 7px 0px;
      .container-time-search{
        height: 25vh !important;
      }
      .container_input-search{
        width : 90%;
        margin-top : 30px;
        input[type="text"]{
          width : 90% !important;
        }
      }
    }
    #detail-search .container-detail-search{
      margin-top: 70px !important;
      width: 100% !important;
      padding-left: 1.2rem;
      padding-right: 1.2rem;
      .custom-col-logo{
          display: none;
      }
      .custom-row{
        margin : 0px !important;
      }
    }
    #detail-search-new .container-detail-search{
      margin-top: 70px !important;
      width: 100% !important;
      padding-left: 1.2rem;
      padding-right: 1.2rem;
      .custom-col-logo{
          display: none;
      }
      .custom-row{
        margin : 0px !important;
      }
    }
    .nav_custom-pagination .pagination_custom{
      display: none;
    }
  }


  @media screen and (max-width : 425px){
    .nav_custom-pagination{
      .button-load-more{
        display: block !important;
        color: #06243c;
        border: 1px solid #e6e7e8;
        padding: 6px 20px;
        border-radius: 5px;
      }
    }
  }

  

  